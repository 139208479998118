import React from 'react';
import { TextVariant } from '@naf/text';
import { useHistory, useLocation } from 'react-router';
import { FrontPageType } from '../../../../../../types/frontPageType';
import BlockContent from '../../../../components/block-content/BlockContent';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import MemoizedImageOrVideo from '../../MemoizedImageOrVideo';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';
import * as S from './Hero.styles';

interface Props {
  hero?: FrontPageType['hero'];
}

export const Hero = ({ hero }: Props) => {
  const history = useHistory();
  const primaryCtaUrl = useDocumentUrlWithNode(hero?.ctaButton?.data);
  const secondaryCtaUrl = useDocumentUrlWithNode(hero?.secondaryCtaButton?.data);
  const datalayer = useGTMDataLayer();
  const location = useLocation();

  const handleTracking = (text: string, path: string) => {
    datalayer?.push({
      event: 'cta_click',
      cta_text: text,
      cta_location: location.pathname,
      cta_url: path,
      ctaPosition: 'hero-banner',
    });
  };

  return (
    (hero && (
      <S.HeroContainer>
        <MemoizedImageOrVideo
          imageId={hero.image?.publicId}
          videoId={hero.video?.publicId}
          altText={hero.image?.altText}
        />
        <S.ContentContainer>
          <S.HeroContent>
            <S.Heading variant={TextVariant.Display}>{hero.header}</S.Heading>
            <BlockContent value={hero.body} />
            <S.ButtonWrapper>
              {hero.ctaButton ? (
                <S.StyledButton
                  size="large"
                  variant="signature"
                  onClick={() => {
                    if (primaryCtaUrl) {
                      handleTracking(hero.ctaButton.buttonText, primaryCtaUrl);
                      history.push(primaryCtaUrl);
                    }
                  }}
                >
                  {hero.ctaButton.buttonText}
                </S.StyledButton>
              ) : null}
              {hero.secondaryCtaButton ? (
                <S.StyledButton
                  size="large"
                  variant="outline"
                  onClick={() => {
                    if (secondaryCtaUrl) {
                      handleTracking(hero.secondaryCtaButton.buttonText, secondaryCtaUrl);
                      history.push(secondaryCtaUrl);
                    }
                  }}
                >
                  {hero.secondaryCtaButton.buttonText}
                </S.StyledButton>
              ) : null}
            </S.ButtonWrapper>
          </S.HeroContent>
        </S.ContentContainer>
      </S.HeroContainer>
    )) ||
    null
  );
};
